
import { Component, Vue } from "vue-property-decorator";
import ScrollToTop from "@/components/ScrollToTop.vue";
import { EventBus } from "@/event-bus";
import { Translator } from "vue-google-translate";

@Component({
  components: {
    ScrollToTop,
    Translator,
  },
})
export default class MasterLayout extends Vue {
  favoritesCount = 0;
  englishIsSelected = false;
  compareCount = 0;
  arrayOfCountries = [
    {
      code: "en|en",
      title: "English",
    },
    {
      code: "en|es",
      title: "Spanish",
    },
  ];

  toggleLanguage() {
    this.englishIsSelected = !this.englishIsSelected;
    //  localStorage.setItem("code", this.englishIsSelected ? "" : "es");
    const selectedLanguage = this.englishIsSelected ? "" : "es";
    console.log("testing");

    localStorage.setItem('englishIsSelected', this.englishIsSelected ? "1" : "0");
    window.googleTranslateElementInit(selectedLanguage);

    //return false;
  }

  data() {
    return {
      mobile: "18774532721",
    };
  }

  mounted() {
    this.favoritesCount = this.latestfavoritesCount();
    this.compareCount = this.latestCompareCount();
  }

  created() {
    EventBus.$on("compare-list-modified", (cnt: number) => {
      this.compareCount = cnt;
    });

    EventBus.$on("fav-list-modified", (cnt: number) => {
      this.favoritesCount = cnt;
    });
  }

  navigateToUrl(url: string) {
    this.$router.push(url);
  }

  latestfavoritesCount(): number {
    const tmp = sessionStorage.getItem("favoritesCount");
    if (tmp == null) {
      console.log("fav-0");
      return 0;
    }
    console.log("latestfavoritesCount");
    console.log(+tmp);
    return +tmp;
  }

  latestCompareCount(): number {
    const tmp = sessionStorage.getItem("compareCount");
    if (tmp == null) {
      console.log("compare-0");
      return 0;
    }
    console.log("latestCompareCount");
    console.log(+tmp);
    return +tmp;
  }
}
