
import { Component, Mixins } from "vue-property-decorator";
import ScrollToTopMixin from "@/mixins/scroll-to-top.mixin";

@Component({})
export default class ScrollToTop extends Mixins(ScrollToTopMixin) {
  private visible = false;
  private visibleoffset = 300;

  mounted() {
    window.addEventListener("scroll", this.catchScroll);
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.catchScroll);
  }

  catchScroll(e: Event) {
    this.visible = window.scrollY > this.visibleoffset;
  }
}
